<template>
<loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading
          heading="Checklist Classification"
          sub-heading="Classifying checklist templates allows for extensive reporting on work done by your teams."
        />
      </column>
      <column class="is-narrow">
        <router-link :to="{
          name: 'checklist-classifications'
        }">Back</router-link>
      </column>
    </columns>

    <form>
        <columns>
            <column>
                <text-input
                    classes="is-rounded is-medium"
                    required
                    :error="$root.errors.name"
                    :value="checklist_classification.name"
                    @input="updateName"
                    placeholder="Eg. survey, maintenance, commissioning">
                    Name
                </text-input>
            </column>
        </columns>

        <report-configuration />

        <columns>
            <column>
                <submit-button
                    class="is-rounded is-medium"
                    :working="working"
                    @submit="createClassification">
                    Save
                </submit-button>
            </column>
        </columns>
    </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { checklistClassification as backend } from '@/api'
import ReportConfiguration from './report-configuration/ReportConfig.vue'

export default {

    components: {
        ReportConfiguration
    },

    data: () => ({
        loading: true,
        working: false,
        classifications: []
    }),

    async beforeCreate() {
        await backend.loadList(({data}) => {
            this.classifications = data
            this.loading = false
        }, () => this.loading = false)
    },

    beforeDestroy() {
        this.clearChecklistClassification()
    },

    methods: {
        ...mapMutations('checklistClassification', [
            'updateName',
            'clearChecklistClassification'
        ]),
        createClassification() {
            this.working = true
            this.$store.dispatch('checklistClassification/create').then(() => {
                this.$toast.success('Classification created')
                this.$router.push('checklist-classifications')
                this.working = false
            }).catch(() => this.working = false)
        }
    },

    computed: {
        ...mapGetters('checklistClassification', [
            'checklist_classification'
        ])
    }

}
</script>